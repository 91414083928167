import { Controller } from "@hotwired/stimulus"
import WellnessCommonController from "./wellness_common_controller.js"

// Connects to data-controller="wellness-journey"
export default class extends WellnessCommonController {
  
  /* **************************************************************************
  function: addTimePeriod
  * User triggered
  * Adds additional start and end dates for time-frame
  ************************************************************************** */
  addTimePeriod(e) {
    e.preventDefault()
    var item = e.currentTarget

    var date_start = new Date();
    date_start.setDate(date_start.getDate() - 30);
    var date_start_string = date_start.toISOString().split('T')[0];

    var date_end = new Date();
    var date_end_string = date_end.toISOString().split('T')[0];

    var content = '<div id="time_period_' + item.dataset.index + '"><em class="fa fa-times text-danger float-end pointer me-1" data-action="click->wellness-journey#removeTimePeriod"></em><h5>Time Period #' + (parseInt(item.dataset.index) + 1) + '</h5><div class="report-setting-section-group"><div class="input-group"><input type="text" name="wj_date_start[]" id="wj_date_start_' + item.dataset.index + '" class="form-control form-control-sm datepicker" value="' + date_start_string + '" autocomplete="off"><span class="input-group-addon px-2">to</span><input type="text" name="wj_date_end[]" id="wj_date_end_' + item.dataset.index + '" class="form-control form-control-sm datepicker"  value="' + date_end_string + '" autocomplete="off"></div></div></div>'

    document.getElementById('time-periods').insertAdjacentHTML('beforeend', content);
    item.dataset.index = parseInt(item.dataset.index) + 1

    $(".datepicker").datepicker({
      changeMonth: true,
      changeYear: true,
      minDate: ($('#default_start_date').length > 0) ? new Date($('#default_start_date').val()) : new Date('2010-01-01'),
      dateFormat: 'yy-mm-dd'
    });
  }


  /* **************************************************************************
  function: buildChartRow
  * System triggered
  * Builds each row for the chart itself
  * For the snapshot display each row contains the value, annotation and tooltip in an array
  ************************************************************************** */
  buildChartRow(elements, type, chart_kind, focus = undefined) {
    console.log("WJ: buildChartRow")

    var row = []  // ex: ['Campaign Name',  1.18, '1.18']
    var element_list = Array.from(elements) // Duplicates the elements array and allows modification without changing the original
    var general_data = element_list.shift()

    if(general_data['total'] > 0){ 
      row.push(general_data['campaign_name'])

      element_list.forEach((e, index) => {
        if(type == 'mean_distress') {
          row.push(e)
        } 
        else if(type == 'snapshot') {
          if(focus && chart_kind == 'line') {

            if(!focus.includes(index.toString())){
              row.push(e[0])
              row.push('')
              row.push(e[2])
            } else {
              row.push(e[0])
              row.push(e[1])
              row.push(e[2])
            }

          } else {
            row.push(e[0])
            row.push(e[1])
            row.push(e[2])
          }
        } 
      })


      // Removes the annotation from the mean distress national average (red line)
      if(focus && type == 'mean_distress' && chart_kind == 'line') { 
        row[5] = ''
      }

      return row
    }
  }
  


  /* **************************************************************************
  function: buildTimePeriodList
  * Internal triggered
  * Build the list of dropdown options for selected options
  ************************************************************************** */
  buildTimePeriodList(data) {
    var item = document.getElementById("wj-time-period-select")
    item.querySelector('.dropdown-menu').innerHTML = ''
    var current_list = undefined
    var element = undefined
    let time_period = document.querySelector('input[name="wj_time_period"]:checked').value
    if(time_period == 'campaigns' || time_period == 'interval') {
      document.getElementById('wj-time-period-select').classList.remove('hidden')
      if(time_period == 'campaigns'){
        current_list = JSON.parse(document.getElementById('wj_time_period_campaign').value)
      }
      if(time_period == 'interval'){
        var checked_interval = document.querySelector('input[name="wj_time_interval"]:checked').value
        if(checked_interval == 'annually') {
          current_list = JSON.parse(document.getElementById('wj_time_period_annually').value)
        } else if(checked_interval == 'bi-annually') {
          current_list = document.getElementById('wj_time_period_biannually').value != '' ? JSON.parse(document.getElementById('wj_time_period_biannually').value) : ''
          element = document.getElementById('wj_time_period_biannually')
        } else if(checked_interval == 'quarterly') {
          current_list = document.getElementById('wj_time_period_quarterly').value != '' ? JSON.parse(document.getElementById('wj_time_period_quarterly').value) : ''
          element = document.getElementById('wj_time_period_quarterly')
        } else if(checked_interval == 'monthly') {
          current_list = document.getElementById('wj_time_period_monthly').value != '' ? JSON.parse(document.getElementById('wj_time_period_monthly').value) : ''
          element = document.getElementById('wj_time_period_monthly')
        }
      }

      var list_data = []
      data.mean_distress.rows.forEach(function(row, index) {
        var node = item.querySelector('.dropdown-menu').appendChild(document.createElement('li').appendChild(document.createElement('button')))
        if(current_list !== '') {
          current_list.forEach((cl_item) =>{
            if(cl_item[0] == row[0].campaign_name) {
              if(cl_item[1] == true) {
                node.innerHTML = '<i class="fas fa-check me-1"></i> ' + row[0].campaign_name
              } else {
                node.innerHTML = '<i class="fas fa-check me-1 text-white"></i> ' + row[0].campaign_name 
              }
            }
          })
        } else {
          node.innerHTML = '<i class="fas fa-check me-1"></i> ' + row[0].campaign_name 
          list_data.push([row[0].campaign_name, true])
        }

        node.classList.add('dropdown-item')
        node.dataset.action = "click->wellness-journey#toggleTimePeriods";
        node.dataset.value =  row[0].campaign_name 
        node.setAttribute("type", "button");
      })

      if(list_data.length > 0) {
        element.value = JSON.stringify(list_data)
      }

    } else {
      document.getElementById('wj-time-period-select').classList.add('hidden')
    }
  }


  /* **************************************************************************
  function: checkForChangeFromDefault
  * Internal triggered
  * Determines if the options selected have changed from the originally loaded settings
  * This is where most of the JS issues arise when trying to reset the options.
  ************************************************************************** */
  checkForChangeFromDefault() {
    console.log("WJ: checkForChangeFromDefault")

    // console.log("    document.wj_resetFilter", document.wj_resetFilter)
    // console.log("    document.wj_saved_filter_loaded", document.wj_saved_filter_loaded)

    if(document.wj_resetFilter == true){
      document.wj_saved_report_settings_last = document.wj_saved_report_settings_default
    }

    var array1 = JSON.parse(document.wj_saved_report_settings_default);
    var array2 = JSON.parse(document.wj_saved_report_settings_last);

    var comparison =  super.arrayDiffAssoc(array1, array2);
    var comparison2 =  super.arrayDiffAssoc(array2, array1);

    console.log("Array 1:", array1)
    console.log("Array 2:", array2)

    console.log("Comparison 1:", comparison)
    console.log("Comparison 2:", comparison2)

    if(Object.keys(comparison).length > 0 || Object.keys(comparison2).length > 0){
      // Notice on "Configure Report" button
      // Notice in header area to reset
      // Notice in settings panel to reset
      // Notice in settings panel to save as "Saved Configuration"
      $(".wj-report-settings-filter-applied-notice").removeClass('hidden');
      if(document.wj_saved_filter_loaded && document.wj_saved_filter_loaded > 0)
      {
        $(".wj-report-settings-filter-save-btn").addClass('hidden');
        $(".wj-report-settings-filter-save-as-new-btn").removeClass('hidden');
        // Check to see if the saved_filter_loaded differs from the current last filter
        $(".wj-report-settings-filter-update-btn").removeClass('hidden');
      }
      else
      {
        $(".wj-report-settings-filter-update-btn").addClass('hidden');
        $(".wj-report-settings-filter-save-as-new-btn").addClass('hidden');
        $(".wj-report-settings-filter-save-btn").removeClass('hidden');
      }
    } else {
      $(".wj-report-settings-filter-applied-notice").addClass('hidden');
      $(".wj-report-settings-filter-save-btn").addClass('hidden');
      $(".wj-report-settings-filter-update-btn").addClass('hidden');
      $(".wj-report-settings-filter-save-as-new-btn").addClass('hidden');
    }

    super.setReportPanelSize()
  }



  /* **************************************************************************
  function: clearSnapshotLabel
  * Internal triggered
  * Resets and hides the snapshot label description gray box.
  ************************************************************************** */
  clearSnapshotLabel() {
    console.log("WJ: clearSnapshotLabel")
    $('#wj_report-name-holder').addClass('hidden')
    $('#wj_report-name').html('')
    $('#wj_report-description').html('')
    $('#wj_report-created-by').html('')
  }

  

  /* **************************************************************************
  function: closeSettings
  * User triggered
  * Closes the off-canvas settings panel
  ************************************************************************** */
  closeSettings(event) {
    console.log("WJ: closeSettings")
    if( $("#wj_report-settings-offcanvas").hasClass("show")){
      $("#wj_report-settings-offcanvas").removeClass("show")
    }
  }


  
  /* **************************************************************************
  function: connect
  * System triggered
  * 
  ************************************************************************** */
  connect() {
    console.log("WJ: connect")
    document.isLoading = false

    if($('#wj_configuration_panel')){
      document.over_time_chart_data = {}
      document.wjControllerRef = this

      var offcanvas = document.getElementById('wj_report-settings-offcanvas');
      document.bsOffcanvas_journey = new bootstrap.Offcanvas( offcanvas );

      super.showLoader('wj_')
      
      if($('#wj_i_can_create_snapshot_folders').val() == 'true' || ($('#wj_saved_report_settings_last').length && $('#wj_default_loaded_report'.length))) {
        document.wjControllerRef.initializeData()
      } else {
        $('#wj_configuration_panel').addClass("hidden");
        $('#ranges-explanation-all').addClass('hidden')
        $("<p>" + I18n.wellness_journey.admin_no_reports_assigned + "</p>").insertBefore('#ranges-explanation-all')
      }
    }

  }


  hideTimePeriodDropDown(e) {
    var list = []
    var time_period = undefined
    var element = undefined
    var item = e.currentTarget

    item.parentNode.querySelectorAll('.dropdown-item').forEach(function(obj, index){
      list[obj.dataset.value] = !obj.querySelector('.fa-check').classList.contains('text-white')
    })

    let wj_time_period = document.querySelector('input[name="wj_time_period"]:checked').value
    
    if(wj_time_period == 'campaigns' || wj_time_period == 'interval'){
      document.getElementById('wj-time-period-select').classList.remove('hidden')
      if(wj_time_period == 'campaigns'){
        time_period = JSON.parse(document.getElementById('wj_time_period_campaign').value)
        element = document.getElementById('wj_time_period_campaign')
      }
      if(wj_time_period == 'interval'){
        var checked_interval = document.querySelector('input[name="wj_time_interval"]:checked').value
        if(checked_interval == 'annually') {
          time_period = JSON.parse(document.getElementById('wj_time_period_annually').value)
          element = document.getElementById('wj_time_period_annually')
        } else if(checked_interval == 'bi-annually') {
          time_period = JSON.parse(document.getElementById('wj_time_period_biannually').value)
          element = document.getElementById('wj_time_period_biannually')
        } else if(checked_interval == 'quarterly') {
          time_period = JSON.parse(document.getElementById('wj_time_period_quarterly').value)
          element = document.getElementById('wj_time_period_quarterly')
        } else if(checked_interval == 'monthly') {
          time_period = JSON.parse(document.getElementById('wj_time_period_monthly').value)
          element = document.getElementById('wj_time_period_monthly')
        }
      }
    } else {
      document.getElementById('wj-time-period-select').classList.add('hidden')
    }
   
    time_period.forEach(function(obj){
      if(list[obj[0]] == undefined) {
        obj[1] = false
      } else {
        obj[1] = list[obj[0]]
      }
    });

    element.value = JSON.stringify(time_period)
    document.getElementById('submit-over-time').click()
  }

  /* **************************************************************************
  function: initializeData
  * System triggered
  * Checks if we have saved data and submits the settings form
  ************************************************************************** */
  initializeData() {
    console.log("WJ: initializeData")
    document.wjControllerRef.setInitialSettings()

    if(document.wj_initial_report_settings !== undefined) {
    
      // console.log("WE ARE LOADING DEFAULT WELLNESS JOURNEY STUFF")
      // console.log("Initial Settings:", document.wj_initial_report_settings)
      document.isLoading = true

      document.wj_saved_filter_loaded = undefined;
      document.wj_edit_saved_filter_loaded = undefined;
      document.wj_initial_report_load_complete = false

      super.loadQuestion('wj_primary_question')
      document.wjControllerRef.resetFilterOptions()
      document.wjControllerRef.loadSavedReportSettings()
      super.setReportPanelSize('wj_')


      // Check if we have previously saved chart data and load that, then submit the form
      // if( document.getElementById("previous_data") !== null ) {
      //   alert("Woot")
      // }


      // Submits the settings panel form
      document.getElementById('submit-over-time').click() 


    } else {
      $('#configureWellnessJourney').addClass("hidden");
      $('#ranges-explanation-all').addClass('hidden')
      $("<p>" + I18n.wellness_snapshot.admin_no_reports_assigned + "</p>").insertBefore('#ranges-explanation-all')
      document.isLoading = false
    }

   
  }



  /* **************************************************************************
  function: loadSavedReportSettings
  * User and Internal triggered
  * Loads settings from a saved snapshot report, resets all settings and processes new list.
  * Main function to handle all resetting and loading of new data
  ************************************************************************** */
  loadSavedReportSettings(controllerRef, settings){
    console.log("WJ: loadSavedReportSettings")

    document.isLoading = true

    // Clear additional time frames to start
    document.getElementById('time-periods').innerHTML = '';

    // This loads the default (last saved report)
    if($('#wj_tertiary_question_holder').length){ $('#wj_tertiary_question_holder').addClass('hidden'); }

    var primary_question_id = ''
    var secondary_question_id = ''
    var tertiary_question_id = ''

    var loaded_settings = false

    // Fully resetting options
    if(document.wj_resetFilter == true){
      document.wj_loadSettingsFromSerializedString = true;
      var dataParsed = document.wj_initial_report_settings;
      var dataKeys = Object.keys(dataParsed);
    } else {
      // Load the current panel for settings
      document.wj_saved_report_settings_default = JSON.stringify(document.wj_initial_report_settings)
      if( ($("#wj_saved_report_settings_last").length && $("#wj_saved_report_settings_last").val() != "") || settings !== undefined) {
        // Check if we changed any settings and if so set the current settings to that.
        if(settings !== undefined) {
          loaded_settings = true
          document.wj_saved_report_settings_last = settings;
        } else {
          document.wj_saved_report_settings_last = $("#wj_saved_report_settings_last").val();
        }
        
        // Check if we have the same wbi version. If not set current settings to default.
        if( JSON.parse(document.wj_saved_report_settings_default).wbi_version != JSON.parse(document.wj_saved_report_settings_last).wbi_version) {
          document.wj_saved_report_settings_last = document.wj_saved_report_settings_default;
        }

        if($("#wj_saved_report_settings_last_save_id").length) {
          document.wj_saved_filter_loaded = $("#wj_saved_report_settings_last_save_id").val();
        }
      } else {
        document.wj_saved_report_settings_last = document.wj_saved_report_settings_default;
      }

      document.wj_loadSettingsFromSerializedString = true;
      var dataParsed = JSON.parse(document.wj_saved_report_settings_last);
      var dataKeys = Object.keys(dataParsed);
    }
  
    // UNCHECK CHECKBOXES/MULTI-SELECTs
    for(var i=0; i < dataKeys.length; i++){
      var name = dataKeys[i];
      var value = dataParsed[name];

      if(name.indexOf('[') == -1 || $("#wj-report-settings-panel-form").find("[name='"+name+"']").length > 0)
      {
        if(name == 'report_id') { 
          // do nothing
        }

        if(name == 'primary_question' && value != '') {
          // Originally used for generating the custom option lists. may not be needed ER 8/10/23
          primary_question_id = value

          // $('#primary_option_list_btn').attr('href', '/wellness_common/' + primary_question_id.split('-')[0] + '/configure_options')

          $('#wj_dashboard-filter-options').removeClass('hidden');
          $('#wj_primary_question').val(primary_question_id).trigger('change');
          // this.changePrimaryQuestionProcess(primary_question_id)
        }

        if(name == 'filter_by_primary_option' && value == 1){
          $('#wj_primary_question_options').removeClass("hidden");
        }
        
        if(name == 'secondary_question' && value != '') {

          console.log("-=-=-=-=-=----- SECONDARY QUESTION ----=-=-=-=-=-=")

          secondary_question_id = value

          if($('#wj_tertiary_question').length){
            // Load Tertiary Question
            $.ajax({
              headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
              url: "/wellness_common/get_signup_questions",
              method: "post",
              data: { 'selected_question': secondary_question_id, 'primary_question': primary_question_id },
              async: false
            }).done(function( data){
              if(data.status == 200){
                $('#wj_tertiary_question_holder').removeClass('hidden');
                $('#wj_tertiary_question').html( document.wcControllerRef.processQuestionList(data.questions) );
              }
            });
          }

          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            type: "POST",
            url: "/wellness_common/get_signup_question_option_list",
            data: { selected_question: secondary_question_id },
            async: false,
            success: function (data) {
              if(data.status == 200) {
                $('#wj_secondary_option_kinds').html( document.wcControllerRef.processOptionKind(data.options) )
              }
            }
          });
  
          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            url: "/wellness_common/get_signup_question_options",
            method: "post",
            data: { "selected_question": secondary_question_id },
            async: false
          }).done(function(data){
            if(data.status == 200) {
              $('#wj_secondary_questions').removeClass('hidden')
              $('#wj_secondary_options').html( document.wcControllerRef.processOptionList(data.options) );
            }
          });
        }
  
        if(name == 'filter_by_secondary_option' && value == 1){
          $('#wj_secondary_question_options').removeClass("hidden");
        }
  
        if(name == 'tertiary_question' && value != '') {
          $('#wj_tertiary_question_holder').removeClass('hidden');
          $('#wj_tertiary_questions').removeClass('hidden');
  
          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            url: "/wellness_common/get_signup_question_options",
            method: "post",
            data: { "selected_question": value },
            async: false
          }).done(function(data){
            if(data.status == 200){
              $('#wj_tertiary_options').html( document.wcControllerRef.processOptionList(data.options) );
            }
          });
        }
  
        if(name == 'filter_by_tertiary_option' && value == 1){
          $('#wj_tertiary_question_options').removeClass("hidden");
        }
  
        // Choose Time Period
        if(name == 'wj_time_period') {
          if(value == 'campaigns' && $("#wj_campaign_options").length) {
            $('#wj_campaign_options').removeClass('hidden');
            $("#wj_time_period").closest('.btn').click()
          }
          if(value == 'date-range') {
            $('#wj_date_list').removeClass('hidden');
          }
          if(value == 'interval' || (value == 'campaigns' && !$("#wj_campaign_options").length)) {
            $('#wj_interval').removeClass('hidden');
            $("#wj_time_interval").closest('.btn').click()
          }
        }

        if(name == 'assess_filter') {
          $('#wj_assess_filter').val('last_in_period')
        }
      }
      else if(name.indexOf('[') != -1)
      {
        // console.log("XB")
        // Multi-select selectbox or checkbox array
        var nameParts = name.split("[");
        name = nameParts[0]+"[]";
        var $element = $("#wj-report-settings-panel-form").find("[name='"+name+"']");
        if($element.length > 0)
        {
          var inputType = $element.prop("type");
          if(inputType == "checkbox") {
            $element.prop("checked",false);
          } else if(inputType == "select-multiple") {
            $element.children("option").prop("selected", false);
          }
  
        }
      }
    }

    // SELECT NEW VALUES
    console.log("=============== ADDING NEW VALUES ================")
    var nonExistingIndexes = [];
    for(var i = 0; i < dataKeys.length; i++){
      var name = dataKeys[i];
      var value = dataParsed[name];

      // Updating display toggle buttons
      if(name == 'chart_type') {
        $('#wj_chart').html($('#wj_chart_default').parent().parent().find('button[data-kind=' + value +']').data('label'))

        if($('#mean-full-chart').hasClass('hidden')){
          $('#wj_chart_default').parent().parent().find('button[data-kind=' + value +']').click()
        }
      }
      if(name == 'wj_form_data') {
        $('#wj_data').html($('#wj_data_default').parent().parent().find('button[data-kind=' + value +']').data('label'))
        $('#wj_data_default').parent().parent().find('button[data-kind=' + value +']').click()
      }
      if(name == 'wj_mean_full_chart') {
        $('#mean-full-chart').find('button[data-value=' + value + ']').click()
      }
      if(name == 'wj_form_focus') {
        $('#focus-kinds').find('button').each(function(){
          $(this).addClass('btn-disabled')
        })
        
        var items = value.split('|')
        items.forEach(element => {
          $('#focus-kinds').find('button[data-kind=' + element + ']').click()
        })
      }

      if(name.indexOf('[') == -1 || $("#wj-report-settings-panel-form").find("[name='"+name+"']").length > 0)
      {
        if(name == 'report_id') { 
          // do nothing - we set this above when selecting the saved report
        } 

        if(name == 'primary_question' && value != '') {
          // Originally used for generating the custom option lists. may not be needed ER 8/10/23
          primary_question_id = value
          $('#primary_question_customize_options').attr('href', '/wellness_common/' + primary_question_id + '/configure_options')

          $('#dashboard-filter-options').removeClass('hidden');
          $('#chart-sort-by').removeClass('hidden');
  
          var obj = ["wj_", primary_question_id, 'primary']
          super.changeQuestion(obj, dataParsed['primary_option_kinds'])
        }

        if(name == 'customize_primary_option_group' && value == 1){
          $('#wj_primary_question_option_groups').removeClass('hidden');
        }

        if(name == 'filter_by_primary_option' && value == 1){
          $('#wj_primary_question_options').removeClass("hidden");
        }

        
        
        if(name == 'secondary_question' && value != '') {

          secondary_question_id = value

          // TODO: Look at reloading this farther down instead of here.
          if($('#wj_tertiary_question').length){
            // Load Tertiary Question
            $.ajax({
              headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
              url: "/wellness_common/get_signup_questions",
              method: "post",
              data: { 'selected_question': secondary_question_id, 'primary_question': primary_question_id },
              async: false
            }).done(function( data){
              if(data.status == 200){
                $('#wj_tertiary_question_holder').removeClass('hidden');
                $('#wj_tertiary_question').html( document.wcControllerRef.processQuestionList(data.questions) );
              }
            });
          }

          $('#wj_secondary_questions').removeClass('hidden')
          var obj = ["wj_", secondary_question_id, 'secondary']
          super.changeQuestion(obj, dataParsed['secondary_option_kinds'])
          // this.changeSecondaryQuestionProcess(secondary_question_id, dataParsed['secondary_option_kinds'])
        }
  
        if(name == 'customize_secondary_option_group' && value == 1){
          $('#wj_secondary_question_option_groups').removeClass('hidden');
        }

        if(name == 'filter_by_secondary_option' && value == 1){
          $('#wj_secondary_question_options').removeClass("hidden");
        }

  
        if(name == 'tertiary_question' && value != '') {
          $('#wj_tertiary_question_holder').removeClass('hidden');
          $('#wj_tertiary_questions').removeClass('hidden');

          tertiary_question_id = value
          var obj = ["wj_", tertiary_question_id, 'tertiary']
          super.changeQuestion(obj, dataParsed['tertiary_option_kinds'])
          // this.changeTertiaryQuestionProcess(tertiary_question_id)
        }
  
        if(name == 'tertiary_option_kinds' && value == "default"){
          $('#wj_tertiary_question_options_filter').removeClass("hidden");
        }

        if(name == 'filter_by_tertiary_option' && value == 1){
          $('#wj_tertiary_question_options').removeClass("hidden");
        }
  
        // Choose Time Period
        if(name == 'wj_time_period') {
          if(value == 'campaigns' && $("#wj_campaign_options").length) {
            $('#wj_campaign_options').removeClass('hidden');
          }
          if(value == 'date-range') {
            $('#wj_date_list').removeClass('hidden');
          }
          if(value == 'interval' || (value == 'campaigns' && !$("#wj_campaign_options").length)) {
            $('#wj_interval').removeClass('hidden');
            $("#wj_time_interval").closest('.btn').click()
          }
        }

        if(name == 'wj_form_view') {
          if(value == 'individual-campaigns') {
            $('#wj_campaign_list_items').removeClass("hidden")
          }
          if(value == 'all-campaigns') {
            $('#wj_campaign_list_items').addClass("hidden")
          }
        }
  
        //     if(name == 'display_response_as') {
        //       // Specifically filter options for participant display.
        //       if(value == 'participants') {
        //         $('input[name="sort_distress_by"][value="thriving"]').parent().addClass('hidden');
        //         $('input[name="sort_distress_by"][value="distressed"]').click();
        //         $('input[name="show_group_total"][value="1"]').parent().addClass("hidden");
        //         $('input[name="show_group_total"][value="0"]').click();
        //         $('input[name="show_organization_total"][value="1"]').parent().addClass("hidden");
        //         $('input[name="show_organization_total"][value="0"]').click();
        //       }
        //     }

        //     if(name == 'campaign_select') {
        //       if(value != '') {
        //         $('#campaign_select').val( value )

        //         super.selectCampaign()
        //         $('#report-campaign-select-comparison-div').removeClass('hidden');
        //       }
        //     }

        var $element = $("#wj-report-settings-panel-form").find("[name='"+name+"']");
        var inputType = $element.prop("type");

        if(inputType == "radio") {
          $("#wj-report-settings-panel-form").find("[name='"+name+"'][value='"+value+"']").parent().click();
          // console.log(" Radio - " + name, value)
        } else if(inputType == "checkbox") {
          $element.each(function(){ 
            $(this).prop("checked",true);
          });
        } else {
          if(name != 'report_id') { 

            if(name == 'wj_time_period_campaign'){

              console.log("wellness journey element: ", $element)

              var my_options = []
              var list = document.getElementById('wj_campaign_list')
       
              for (var i = 0; i < list.options.length; i++) {
                var status = false
                JSON.parse(value).forEach(function(e){
                  if(e[2] == list.options[i].value){
                    status = e[1]
                  }
                })
                my_options.push([list.options[i].text, status, list.options[i].value])
              }
              $element.val(JSON.stringify(my_options));
              break
            } else if(name == 'wj_time_period_annually') {
              $element.val(value);
            } else {
              $element.val(value);
            }
          }
        }
  
    //     if($element.hasClass("select2-hidden-accessible")){
    //       $element.trigger('change.select2');
    //     }
      }
      else if(name == 'wj_date_start[0]' || name == 'wj_date_end[0]') {
        var item_name = name.split("[");
        var item_number = name.substring(name.indexOf('[') + 1, name.lastIndexOf(']'));
        // console.log("=====", item_name[0], item_number, value)
        $('#' + item_name[0] + '_' + item_number).val(value)
      }
      else if(name.indexOf('wj_date_start') != -1 || name.indexOf('wj_end_date') != -1){
        if(name.indexOf('wj_date_start')) {
          var item_number = name.substring(name.indexOf('[') + 1, name.lastIndexOf(']'));
          
          var start_date = dataParsed['wj_date_start[' + item_number + ']']
          var end_date = dataParsed['wj_date_end[' + item_number + ']']

          var content = '<div id="time_period_' + item_number + '"><em class="fa fa-times text-danger float-end pointer me-1" data-action="click->wellness-journey#removeTimePeriod"></em><h5>Time Period #' + (parseInt(item_number) + 1) + '</h5><div class="report-setting-section-group"><div class="input-group"><input type="text" name="wj_date_start[]" id="wj_date_start_' + item_number + '" class="form-control form-control-sm datepicker" autocomplete="off" value="' + start_date + '"><span class="input-group-addon px-2">to</span><input type="text" name="wj_date_end[]" id="wj_date_end_' + item_number + '" class="form-control form-control-sm datepicker" autocomplete="off" value="' + end_date + '"></div></div></div>'
          document.getElementById('time-periods').insertAdjacentHTML('beforeend', content);    
          
          
          document.getElementById('add_time_period').dataset.index = parseInt(item_number) + 1
        }
      }
      else if(name.indexOf('[') != -1)
      {
        // Multi-select selectbox or checkbox array
        var nameParts = name.split("[");
        name = nameParts[0]+"[]";
        var $element = $("#wj-report-settings-panel-form").find("[name='"+name+"']");

        console.log("NAME: ", name)
        console.log("VALU: ", value)
        console.log("ELEM: ", $element)
        
        if($element.length > 0)
        {
          var inputType = $element.prop("type");
          if(inputType == "radio") {
            $("#wj-report-settings-panel-form").find("[name='"+name+"'][value='"+value+"']").click();
          } else if(inputType == "checkbox") {
            $element.each(function(){
              if($(this).val() == value) {
                $(this).prop("checked", true);
              }
            });
          } else if(inputType == "select-multiple") {
            value = value.replaceAll("'", "\\'")
            $element.children("option[value='" + value + "']").prop("selected", true);
          } else if ($element.length == 1){
            $element.val(value);
          }
  
          if($element.hasClass("select2-hidden-accessible")){
            $element.trigger('change.select2');
          }
        }
        else
        {
          nonExistingIndexes.push(i);
        }
      }
    }

    console.log("==-=-=-=-=-== LOAADED SETTINGS: " + loaded_settings + " -=-=-=-=-=-=-====")
    document.isLoading = false
    document.wj_initial_report_load_complete = true
    if(loaded_settings == true) {
      // This happens when you choose a report
      $('#submit-over-time').click()
    }
  }




  /* **************************************************************************
  function: loadSavedSettings
  * User triggered
  * Gathers the data from a saved report setting to load
  ************************************************************************** */
  loadSavedSettings(e) {
    console.log("WJ: loadSavedSettings")
    const controllerRef = this

    e.preventDefault()
    controllerRef.resetFilterOptions()

    var item = e.currentTarget

    var parentContainer = $(item).closest(".report-settings-saved-filter");
    var save_id = parentContainer.data("save_id");

    if(save_id) {
      document.saved_filter_loaded = save_id;

      $(".report-settings-saved-filter.list-group-item-success").removeClass("list-group-item-success");
      parentContainer.addClass("list-group-item-success");

      $('#wj_report_id').val(item.dataset.id)

      if($('#i_can_create_snapshot_folders').val() == 'false') {
        // Reset the default report settings to clicked report if limited admin
        var loaded_filter = JSON.parse(item.dataset.filter)
        loaded_filter.report_id = item.dataset.id
        document.initial_report_settings = loaded_filter
      }

      window.wj_leavePanelOpen = true
      window.wj_reportName = item.dataset.name
      window.wj_reportDescription = item.dataset.description
      window.wj_reportCreatedBy = item.dataset.createdby

      controllerRef.loadSavedReportSettings(controllerRef, item.dataset.filter)
      controllerRef.saveLastReportSettings(controllerRef)
    }
  }


  /* **************************************************************************
  function: removeTimePeriod
  * User triggered
  * Removes selected start and end dates for time-frame
  ************************************************************************** */
  removeTimePeriod(e) {
    e.preventDefault()
    var item = e.currentTarget

    var ans = confirm("Are you sure you want to remove this time period?")
    if(ans) {
      item.parentNode.remove()
    }
  }


  /* **************************************************************************
  function: resetFilterOptions
  * Internal triggered
  * Handles resetting of the form to "factory defaults"
  ************************************************************************** */
  resetFilterOptions() {
    console.log("WJ: resetFilterOptions")
    var date = new Date();

    $('#wj_primary_question').prop('selectedIndex', 0).trigger("change");
    $('#wj_secondary_question').html('');
    if($("#wj_tertiary_question").length) { $('#wj_tertiary_question').html(''); }

    super.resetQuestionPrimary('wj_');
    super.resetQuestionSecondary('wj_');
    super.resetQuestionTertiary('wj_');

    // Reset Other Buttons
    if($('#wj_time_period option').length > 0){
      $('#wj_time_period').closest('.btn').click();
    } else {
      $("#wj_time_interval").closest('.btn').click();
    }

    $('#wj_campaign_list').val('');
    $('#wj_form_view').closest('.btn').click();

    $('#wj_date_start_0').val( $('#wj_default_start_date').val() );
    $('#wj_date_end_0').val( date.toISOString().split('T')[0] );
    $('#wj_date_start_1').val( $('#wj_default_start_date').val() );
    $('#wj_date_end_1').val( date.toISOString().split('T')[0] );

    $('#wj_customize_primary_option_group').closest('.btn').click();
    $('#wj_customize_secondary_option_group').closest('.btn').click();
    if($('#wj_customize_tertiary_option_group').length){ $('#wj_customize_tertiary_option_group').closest('.btn').click(); }

    $('#wj_filter_by_primary_option').closest('.btn').click();
    $('#wj_filter_by_secondary_option').closest('.btn').click();
    if($('#wj_filter_by_tertiary_option').length){ $('#wj_filter_by_tertiary_option').closest('.btn').click() }

    $('#wj_assess_filter').val('last_in_period');

    $('#wj_form_data').val("snapshot")
    $('#wj_form_focus').val("0|3")
    $("#wj_mean_full_chart").val('limited')

    // Reset hidden values from limited by report users
    $('#wj_report_id').val('')
    if(document.wj_saved_report_settings_default !== undefined) {
      var saved_data = JSON.parse(document.wj_saved_report_settings_default)
      $('#wj_report_id').val(saved_data.report_id)

      document.wj_saved_report_settings_last = document.wj_saved_report_settings_default
    }
  }



  /* **************************************************************************
  function: resetSettings
  * User triggered
  * User action to call the reset scripts, reloads default data, saves resetted settings as current settings
  ************************************************************************** */
  resetSettings(e) {
    console.log("WJ: resetSettings")

    document.isLoading = true
    document.wj_resetFilter = true

    this.clearSnapshotLabel()

    super.showLoader('wj_')

    const controllerRef = this
    e.preventDefault()
    $('#wj_dashboard-filter-options').addClass('hidden');
    $('#wj_saved_report_settings_last').remove();
    $('#wj_report-settings-tab-offcanvas').click()

    document.getElementById('snapshot_well-being-continuum').classList.remove("hidden")
    document.getElementById('snapshot_mean-distress-score').classList.add("hidden")
    
    // console.log("document.wj_initial_report_settings: ", document.wj_initial_report_settings)
  
    controllerRef.resetFilterOptions()
    if(document.wj_initial_report_settings){
      document.wj_saved_filter_loaded = undefined;
      $(".report-settings-saved-filter.list-group-item-success").removeClass("list-group-item-success"); // TODO: fix this for journey
      controllerRef.loadSavedReportSettings(controllerRef, JSON.stringify(document.wj_initial_report_settings));
    }

    // Reset Option Buttons
    $('#wj_form_data').val("snapshot")
    $('#wj_form_focus').val("0|3")
    $("#wj_mean_full_chart").val('limited')
    $('#wj_form_chart').val('column')


    $('#wj_data').html($('#wj_data_default').attr('data-label'))
    $('#wj_chart').html($('#wj_chart_default').attr('data-label'))


    $('#wj-chart-type-button').prop('disabled', false)
    $('#wj-chart-type-button').removeClass("hidden")

    $('#mean-full-chart').addClass('hidden')
    $('#mean-full-chart > button').each(function(){
      $(this).removeClass("btn-disabled")
      if($(this).attr('data-value') == 'full'){
        $(this).addClass('btn-disabled')
      }
    })

    $('#focus-kinds').addClass('hidden')
    $('#focus-kinds > button').each(function(){
      $(this).removeClass("btn-disabled")
      if($(this).attr('data-kind') == 1 || $(this).attr('data-kind') == 2) {
        $(this).addClass('btn-disabled')
      }
    })

    controllerRef.saveLastReportSettings("reset_settings");
    document.isLoading = false

    $('#submit-over-time').click()
  }



  /* **************************************************************************
  function: saveLastReportSettings
  * Internal triggered
  * Saves the current settings on the settings form to the "_last_" settings for the user
  ************************************************************************** */
  saveLastReportSettings(location = '') {
    console.log("WJ: saveLastReportSettings")
    var settings_last_data = $("#wj-report-settings-panel-form").serializeArray();

    if($('#i_can_create_snapshot_folders').val() == 'false'){
      var report_id = $('#wj_report_id').val()
    } else {
      report_id = null
    }
    var formData = {settings_data: settings_last_data, save_for: "last", report_id: report_id, report_type: 'wellness_journey' };

    $.post("/reports/save_settings", formData, function(returnDataStr) {
      document.wj_saved_report_settings_last = document.wjControllerRef.getJSONLoadSettingsString($("#wj-report-settings-panel-form"));
      document.wjControllerRef.checkForChangeFromDefault();

      // TODO: This is where the reset is getting hosed up
      if(location == 'reset_settings' || document.wj_resetFilter == true) {
        document.wjControllerRef.updateOverTimeChart();
        document.wj_resetFilter = null
      }
    });
  }


  /* **************************************************************************
  function: setInitialSettings
  * Internal triggered
  * Loads initial settings for the wellness journey report. Settings get updated and reset based on user interaction
  ************************************************************************** */
  setInitialSettings() {

    console.log("WJ: setInitialSettings")

    if($('#wj_i_can_create_snapshot_folders').val() == 'true') {
      var initial_settings = {
        ajax_call: "report",
        wbi_version: $('#wj_wbi_version').val(),
        country: $('#wj_country').val(),
        report_id: "",
        report_type: "wellness_journey",
        display_data: "spectrum",
        chart_type: "column",
        compare: "none",
        primary_question: $('#wj_default_primary_question').val(),
        filter_by_primary_option: "0",
        customize_primary_option_group: "0",
        filter_by_secondary_option: "0",
        customize_secondary_option_group: "0",
        wj_time_interval: "annually",
        mean_national_average: 'show',
        wj_time_period_annually: document.getElementById('wj_time_period_annually').value,
        wj_time_period_biannually: '',
        wj_time_period_quarterly: '',
        wj_time_period_monthly: '',
        wj_form_data: 'snapshot',
        wj_form_focus: "0|3",
        wj_mean_full_chart: "limited",


        date_range_select: "campaign",
        sort_distress_by: "distressed",

        'wj_date_start[0]': $('#wj_date_start_0').val(),
        'wj_date_end[0]': $('#wj_date_end_0').val(),
        
        assess_filter: "last_in_period",
        wj_time_period: $('#wj_campaign_options option').length > 1 ? 'campaigns' : 'interval',
        display_response_as: "percent"
      }


      $('#wj_campaign_options').length ? initial_settings["wj_form_view"] = 'all-campaigns' : ''
      $('#wj_campaign_options').length ? initial_settings["wj_time_period_campaign"] = document.getElementById('wj_time_period_campaign').value : ''

      if($('#wj_default_primary_question').val() != '') {
        initial_settings["primary_option_kinds"] = "0"
        initial_settings["secondary_question"] = ""
      }
     

      if($("#wj_tertiary_question_holder").length) {
        initial_settings = Object.assign({filter_by_tertiary_option: "0"}, initial_settings)
        initial_settings = Object.assign({customize_tertiary_option_group: "0"}, initial_settings)
      }
    } else {

      // LIMITED ACCESS USERS
      if( $('#wj_saved_report_settings_last').length && $('#wj_default_loaded_report'.length)) {
        var saved_report_settings_last = JSON.parse($('#wj_saved_report_settings_last').val())
        var initial_settings = JSON.parse($('#wj_default_loaded_report').val())
        initial_settings.report_id = saved_report_settings_last['report_id']
      }
    }
    
    document.wj_initial_report_settings = initial_settings
  }


  /* **************************************************************************
  function: showLimitedUserText
  * System triggered
  * Shows the questions and options selected if user is limited by report.
  ************************************************************************** */
  showLimitedUserText(){
    // 
    if($('#wj_i_can_create_snapshot_folders').val() == 'false'){
      $('#wj_primary_question_option_text_list').html('')
      $('#wj_secondary_question_option_text_list').html('')
      $('#wj_tertiary_question_option_text_list').html('')

      if($('#wj_primary_question').val() != '') {
        $('#wj_primary_question_option_text_list').removeClass('hidden')
        $('#wj_primary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.question + ':</strong> ' + $("#wj_primary_question option:selected").text() + '</li>')

        if( $("#wj_primary_option_kinds").val() != 0){
          $('#wj_primary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.custom_list + ':</strong> ' + $("#wj_primary_option_kinds option:selected").text() + '</li>')
        }

        if( $("#wj_primary_options").val() != '') {
          var data = $("#wj_primary_options :selected").map(function(i, el) {
            return $(el).text()
          }).get();
          $('#wj_primary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.filter + ':</strong> ' + data.join(', ') + '</li>')
        }
      }

      if($('#wj_secondary_question').val() != '') {
        $('#wj_secondary_question_holder').removeClass('hidden')
        $('#wj_secondary_question_option_text_list').removeClass('hidden')
        $('#wj_secondary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.question + ':</strong> ' + $("#wj_secondary_question option:selected").text() + '</li>')

        if( $("#wj_secondary_option_kinds").val() != 0){
          $('#wj_secondary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.custom_list + ':</strong> ' + $("#wj_secondary_option_kinds option:selected").text() + '</li>')
        }

        if( $("#wj_secondary_options").val() != '') {
          var data = $("#wj_secondary_options :selected").map(function(i, el) {
            return $(el).text()
          }).get();
          $('#wj_secondary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.filter + ':</strong> ' + data.join(', ') + '</li>')
        }
      } else {
        $('#wj_secondary_question_holder').addClass('hidden')
      }

      if($('#wj_tertiary_question').val() != '' && $('#wj_tertiary_question').val() != null) {

        $('#wj_tertiary_question_holder').removeClass('hidden')
        $('#wj_tertiary_question_option_text_list').removeClass('hidden')
        $('#wj_tertiary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.question + ':</strong> ' + $("#wj_tertiary_question option:selected").text() + '</li>')

        if( $("#wj_tertiary_option_kinds").val() != 0){
          $('#wj_wtertiary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.custom_list + ':</strong> ' + $("#wj_tertiary_option_kinds option:selected").text() + '</li>')
        }

        if( $("#wj_tertiary_options").val() != '') {
          var data = $("#wj_tertiary_options :selected").map(function(i, el) {
            return $(el).text()
          }).get();
          $('#wj_tertiary_question_option_text_list').append('<li><strong>' + I18n.wellness_snapshot.filter + ':</strong> ' + data.join(', ') + '</li>')
        }
      } else {
        $('#wj_tertiary_question_holder').addClass('hidden')
      }
    }
  }



  showModal(event) {
    event.preventDefault()
    var myModal = new bootstrap.Modal(document.getElementById('wbj_demo_video'), {})

    myModal.show()
    $('#campaign_overview_video_iframe').attr('src', 'https://player.vimeo.com/video/894966568?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')
  }


  /* **************************************************************************
  function: submitForm
  * System triggered
  * Submits the form to be processed
  ************************************************************************** */
  submitForm(e) {
    console.log("WJ: submitForm")
    e.preventDefault()

    if(window.wj_leavePanelOpen === true) {
      window.wj_leavePanelOpen = false
      $('#wj_report-settings-tab').click()
    } else {
      document.bsOffcanvas_journey.hide()
    }

    if(window.wj_reportName !== undefined ) {
      $('#wj_report-name-holder').removeClass('hidden');
      $('#wj_report-name').html("<h4 class='m-0 p-0'>" + window.wj_reportName + "</h4>")
      window.wj_reportName = undefined
    }
    if(window.wj_reportDescription !== undefined) {
      $('#wj_report-description').html('<p>' + window.wj_reportDescription + '</p>')
      window.wj_reportDescription = undefined
    }
    if(window.wj_reportCreatedBy !== undefined) {
      var name = '<a href="mailto:' + window.wj_reportCreatedBy + '">' + window.wj_reportCreatedBy + '</a>'
      if(window.wj_reportCreatedBy == 'default-wbi-admin@cws.net') {
        name = 'Well-Being Index Team'
      }
      $('#wj_report-created-by').html('<p><small>' + I18n.wellness_snapshot.created_by + ': ' + name + '</small></p>')
      window.wj_reportCreatedBy = undefined
    }

    super.showLoader('wj_')

    document.over_time_chart_data = {}

    document.wjControllerRef.showLimitedUserText()

    // Update View label
    // let wj_form_view = document.getElementById('wj_form_view')
    // document.getElementById('wj_view').innerHTML = wj_form_view.options[wj_form_view.selectedIndex].text

    let formData = $("#wj-report-settings-panel-form").serializeArray()

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      type: "POST",
      url: "/wellness_journey/process_report",
      data: formData,
      success: function (data) {
        if(data.status == 200) {
          if(data.data.mean_distress.rows.length == 0 || data.data.snapshot.rows.length == 0) {
            $('#wj_loader-holder').html("<p class='text-center py-5 my-5'>" + I18n.wellness_snapshot.no_data_found + "</p>");
          } else {

            // Build time period dropdown from selected data.
            document.wjControllerRef.buildTimePeriodList(data.data)

            // Do chart update
            document.over_time_chart_data = data.data

            // Ensure that google charts script is loaded before running.
            add_google_chart_onload_callback(function(){
              document.wjControllerRef.updateOverTimeChart();
            });

            // Save as last settings -- Only after the inital load is complete
            // console.log("document.wj_initial_report_load_complete: ", document.wj_initial_report_load_complete)
            if(document.wj_initial_report_load_complete === true) {
              document.wjControllerRef.saveLastReportSettings();
            } else {
              document.wj_initial_report_load_complete = true;
              document.wjControllerRef.checkForChangeFromDefault();
            }
          }
        } else {
          $('#wj_loader-holder').html("<p class='text-center py-5 my-5'>" + I18n.wellness_journey.cannot_load_chart + "</p>");
        }
      }
    });
  }


  /* **************************************************************************
  function: toggleChart
  * System triggered
  * Reloads the chart data
  ************************************************************************** */
  toggleChart(e) {
    console.log("WJ: toggleChart")

    if(Array.isArray(e)) {
      document.getElementById('wj_form_chart').value = e[0]
      document.getElementById('wj_chart').innerHTML = e[1]
    } else {
      var item = e.currentTarget
      document.getElementById('wj_form_chart').value = item.dataset.kind
      document.getElementById('wj_chart').innerHTML = item.dataset.label

      if(document.getElementById('wj_form_data').value == 'snapshot' && item.dataset.kind == 'column'){
        document.getElementById('focus-kinds').classList.add('hidden')
      } else {
        document.getElementById('focus-kinds').classList.remove('hidden')
      }

      // Update "last" data
      if(document.isLoading === false) {
        document.wjControllerRef.saveLastReportSettings();
      }

      document.wjControllerRef.updateOverTimeChart()
    }

    
  }


  /* **************************************************************************
  function: toggleCollapse
  * Usear triggered
  * Toggles the showing/hiding of the Journey / Snapshot Reports
  ************************************************************************** */
  toggleCollapse(e) {
    console.log("WJ: toggleCollapse")

    var item = e.currentTarget
    var myCollapse = document.getElementById(item.dataset.toggle)
    new bootstrap.Collapse(myCollapse, {})

    if(item.querySelector('.collapse-notification').classList.contains('fa-chevron-up')) {
      item.querySelector('.collapse-notification').classList.remove('fa-chevron-up')
      item.querySelector('.collapse-notification').classList.add('fa-chevron-down')
    }
    else if(item.querySelector('.collapse-notification').classList.contains('fa-chevron-down')) {
      item.querySelector('.collapse-notification').classList.add('fa-chevron-up')
      item.querySelector('.collapse-notification').classList.remove('fa-chevron-down')
    }
  }

  /* **************************************************************************
  function: toggleData
  * System triggered
  * Reloads the chart data
  ************************************************************************** */
  toggleData(e) {
    console.log("WJ: toggleData")
    var item = e.currentTarget

    document.getElementById('wj_form_data').value = item.dataset.kind
    document.getElementById('wj_data').innerHTML = item.dataset.label

    // Show / Hide Focus Options
    if(item.dataset.kind == 'snapshot') 
    {
      document.wjControllerRef.toggleChart(['column', I18n.wellness_journey.column])
      document.getElementById('mean-full-chart').classList.add('hidden')
      document.getElementById('wj-chart-type-button').removeAttribute('disabled')
      document.getElementById('snapshot_well-being-continuum').classList.remove("hidden")
      document.getElementById('snapshot_mean-distress-score').classList.add("hidden")
      document.getElementById('focus-kinds').classList.add('hidden')
      document.getElementById('wj-chart-type-button').classList.remove('hidden')
    } 
    else if(item.dataset.kind == 'mean_distress') 
    {
      // We need to set the chart type to line and then disable the button. 
      // We need to display the full chart options
      document.wjControllerRef.toggleChart(['line', I18n.wellness_journey.line])
      document.getElementById('focus-kinds').classList.add('hidden')
      document.getElementById('mean-full-chart').classList.remove('hidden')
      document.getElementById('wj-chart-type-button').setAttribute('disabled', 'disabled')
      document.getElementById('snapshot_well-being-continuum').classList.add("hidden")
      document.getElementById('snapshot_mean-distress-score').classList.remove("hidden")
      document.getElementById('wj-chart-type-button').classList.add('hidden')
    }

    // Update "last" data
    if(document.isLoading === false) {
      document.wjControllerRef.saveLastReportSettings();
    }

    document.wjControllerRef.updateOverTimeChart()
  }

  /* **************************************************************************
  function: toggleFocus
  * User triggered
  * Changes what line(s) are focused and reloads the chart data from memory (no ajax calls)
  ************************************************************************** */
  toggleFocus(e) {
    console.log("WJ: toggleFocus")
    var item = e.currentTarget

    // Show / Hide Specific Option
    if(item.classList.contains('btn-disabled')){
      item.classList.remove('btn-disabled')
    } else {
      item.classList.add('btn-disabled')
    }

    // Get newly selected focus options.
    var selected = []
    for (const child of document.getElementById('focus-kinds').children) {
      if(!child.classList.contains('btn-disabled') && child.dataset.kind !== undefined){
        // selected.push( child.dataset.kind )
        // Prepends the child.dataset.kind element to the selected array. ES6 spread
        selected = [child.dataset.kind, ...selected]
      }
    }

    let selected_options = selected.join('|')
    document.getElementById('wj_form_focus').value = selected_options

    // Update "last" data
    if(document.isLoading === false) {
      document.wjControllerRef.saveLastReportSettings();
    }

    document.wjControllerRef.updateOverTimeChart()
  }


  /* **************************************************************************
  function: toggleMeanFullChart
  * User triggered
  * Changes if the user sees the full vertical mean chart or a limited zoomed in view
  *    and reloads the chart data from memory (no ajax calls)
  ************************************************************************** */

  toggleMeanFullChart(e) {
    console.log("WJ: toggleMeanFullChart")
    var item = e.currentTarget

    var btn = $(item).parent().children('button')
    for(var i = 0; i < btn.length; i++){
      btn[i].removeAttribute('disabled')
    }

    item.setAttribute('disabled', 'disabled')

    document.getElementById('wj_mean_full_chart').value = item.dataset.value

    // Update "last" data
    if(document.isLoading === false) {
      document.wjControllerRef.saveLastReportSettings();
    }

    document.wjControllerRef.updateOverTimeChart()
  }


  /* **************************************************************************
  function: toggleMeanNationalAverage
  * User triggered
  * Changes if the user sees the red national average not
  *    and reloads the chart data from memory (no ajax calls)
  ************************************************************************** */

  toggleMeanNationalAverage(e) {
    console.log("WJ: toggleMeanNationalAverage")
    var item = e.currentTarget

    var value = ''
    if(item.classList.contains('btn-disabled')){
      item.classList.remove('btn-disabled')
      value = 'show'
    } else {
      item.classList.add('btn-disabled')
      value = 'hide'
    }

    document.getElementById('wj_mean_national_average').value = value

    // Update "last" data
    if(document.isLoading === false) {
      document.wjControllerRef.saveLastReportSettings();
    }

    document.wjControllerRef.updateOverTimeChart()
  }


  /* **************************************************************************
  function: toggleTimePeriods
  * User triggered
  * Shows or hides specific time-frame(s)
  ************************************************************************** */
  toggleTimePeriods(e){
    e.preventDefault()
    var item = e.currentTarget

    if(item.querySelector('.fa-check').classList.contains('text-white')){
      item.querySelector('.fa-check').classList.remove('text-white')
    } else {
      item.querySelector('.fa-check').classList.add('text-white')
    }
  }


  /* **************************************************************************
  function: updateOverTimeChart
  * System triggered
  * Reloads the chart data
  ************************************************************************** */
  updateOverTimeChart() {
    console.log("WJ: updateOverTimeChart")    
    var data = new google.visualization.DataTable();

    var v_ticks = ''
    var v_ticks_baseline = ''
    var view_window_min = 0
    var view_window_max = 100

    // Determine the kind of chart to show based on the wj_form_data value
    let chart_data = document.getElementById('wj_form_data').value
    let chart_kind = document.getElementById('wj_form_chart').value

    var time_period = undefined
    let wj_time_period = document.querySelector('input[name="wj_time_period"]:checked').value
    if(wj_time_period == 'campaigns' || wj_time_period == 'interval') {
      document.getElementById('wj-time-period-select').classList.remove('hidden')
      if(wj_time_period == 'campaigns'){
        time_period = JSON.parse(document.getElementById('wj_time_period_campaign').value)
      }
      if(wj_time_period == 'interval'){
        var checked_interval = document.querySelector('input[name="wj_time_interval"]:checked').value
        if(checked_interval == 'annually') {
          time_period = JSON.parse(document.getElementById('wj_time_period_annually').value)
        } else if(checked_interval == 'bi-annually') {
          time_period = JSON.parse(document.getElementById('wj_time_period_biannually').value)
        } else if(checked_interval == 'quarterly') {
          time_period = JSON.parse(document.getElementById('wj_time_period_quarterly').value)
        } else if(checked_interval == 'monthly') {
          time_period = JSON.parse(document.getElementById('wj_time_period_monthly').value)
        }
      }
    } else {
      document.getElementById('wj-time-period-select').classList.add('hidden')
    }



    // let time_period = document.getElementById('wj_time_period_list').value.split(',')
    var wj = document.over_time_chart_data[chart_data]

    if(wj !== undefined){
      let standard_colors = wj['colors']['standard'] // Distress Focus Colors
      let muted_colors = wj['colors']['muted']
      var enabed_column = 1

      if(wj_time_period == 'date-range') {
        console.log("ZZZZZ: ", wj)
        var time_period = []
        wj['rows'].forEach((item) => {
          time_period.push([item[0]['campaign_name'], true])
        })
      }


      wj['columns'].forEach((element) => {
        if(element[0] == 'annotation') {
          data.addColumn({ type: 'string', role: 'annotation' })
        } else if(element[0] == 'tooltip') {
          data.addColumn({ type: 'string', role: 'tooltip', p: { html: true } })
        } else {
          data.addColumn(element[0], element[1]);
        }
      })

      if(chart_data == 'mean_distress') {
        if(document.getElementById('wj_mean_national_average').value == 'show') {
          var series = { 0: { lineWidth: 6, pointSize: 9 },
                        1: { lineWidth: 3, pointSize: 6, type: 'area' }
                      }
        } else {
          var series = { 0: { lineWidth: 6, pointSize: 9 }}
        }
        var colors = standard_colors
        var stacked = false

        var low = 13  // wj['baseline']
        var high = wj['baseline'] // v_ticks.slice(-1)    


        wj['rows'].forEach((element, index) => {
          time_period.forEach((tp) => {
            if(tp[1] == true && tp[0] == element[0].campaign_name){
              if(document.getElementById('wj_mean_national_average').value == 'show') {
                var row = document.wjControllerRef.buildChartRow(element, 'mean_distress', chart_kind, ['0'])
              } else {
                var copy = Array.from(element)
                delete copy[4];
                delete copy[5];
                delete copy[6];
                var row = document.wjControllerRef.buildChartRow(copy, 'mean_distress', chart_kind, ['0'])
              }
              if(row !== undefined){
                data.addRow(row);
                row.forEach((e) => {
                  if(typeof e == 'number'){
                    if(e < low) { low = e }
                    if(e > high) { high = e }
                  }
                })
              }
            }
          })
        })


        // wj['rows'].forEach((element, index) => {
        //   if(time_period[index][1] == true){
        //     if(document.getElementById('wj_mean_national_average').value == 'show') {
        //       var row = document.wjControllerRef.buildChartRow(element, 'mean_distress', chart_kind, ['0'])
        //     } else {
        //       var copy = Array.from(element)
        //       delete copy[4];
        //       delete copy[5];
        //       delete copy[6];
        //       var row = document.wjControllerRef.buildChartRow(copy, 'mean_distress', chart_kind, ['0'])
        //     }

        //     console.log("ROW: " + row + "     ELEMENT: " + element)

        //     if(row !== undefined){
        //       data.addRow(row);

        //       row.forEach((e) => {
        //         if(typeof e == 'number'){
        //           if(e < low) { low = e }
        //           if(e > high) { high = e }
        //         }
        //       })
        //     }
        //   }
        // })

        // Determine if we show the full -2 to 9 chart or a zoomed in view
        var y_axis_display = document.getElementById('wj_mean_full_chart').value

        if(y_axis_display == 'limited') {
          view_window_max = high + 1
          view_window_min = low - 1

        } else if(y_axis_display == 'full') {
          v_ticks_baseline = wj['baseline']
          v_ticks = wj['v_ticks']

          view_window_min = v_ticks_baseline
          view_window_max = v_ticks.slice(-1)
        }

        
      } else if(chart_data == 'snapshot') {
        var colors = []
        var series = {}
        var stacked = "percent"
        let focus = document.getElementById('wj_form_focus').value.split('|')

        if(document.getElementById('wj_form_chart').value == 'line'){
          for(let i = 0; i < 4; i++) {
            if( focus.includes(i.toString()) ) {
              colors.push( standard_colors[i] )
              series[i] = { lineWidth: 8, pointSize: 11 }
            } else {
              colors.push( muted_colors[i] )
              series[i] = { lineWidth: 3, pointSize: 6 }
            }
          } 
        } else {
          for(let i = 0; i < 4; i++) {
            colors.push( standard_colors[i] )
            series[i] = { lineWidth: 8, pointSize: 11 }
          }
        }

        var low = 100
        var high = 0

        wj['rows'].forEach((element, index) => {
          if(time_period !== undefined) {
            time_period.forEach((tp) => {
              if(tp[1] == true && tp[0] == element[0].campaign_name){
                var row = document.wjControllerRef.buildChartRow(element, 'snapshot', chart_kind, focus)
                if(row !== undefined){
                  data.addRow(row);
                  row.forEach((e) => {
                    if(typeof e == 'number'){
                      if(e < low) { low = e }
                      if(e > high) { high = e }
                    }
                  })
                }
              }
            })
          }
        })

        if(chart_kind == 'line') {
          view_window_min = Math.round(low) - 3
          view_window_max = Math.round(high) + 3
        } else if(chart_kind == 'column') {
          view_window_min = 0
          view_window_max = 1
        }
      }

      var options = {
        colors: colors,
        hAxis: {
          textStyle: {
            fontSize: 12
          },
          gridlines: {
            color: '#EEE',
          },
          // format: 'MMM y',
          // viewWindow: {
          //   min: new Date(document.over_time_chart_data['general']['start_date']),
          //   max: new Date(document.over_time_chart_data['general']['end_date'])
          // }

        },
        chartArea: {
          height: 400,
          left: 40,
          top: 40,
          right: 40,
          width: "100%",
        },
        legend: 'none',
        height: 480,
        vAxis: {
          textStyle: {
            fontSize: 12
          },
          gridlines: {
            color: '#EEE',
            multiple: .5
          },
          viewWindow: {
            min: view_window_min,
            max: view_window_max
          },
          ticks: v_ticks,
          viewWindowMode: "pretty",
          baseline: v_ticks_baseline
        },
        tooltip: {
          isHtml: true
        },
        series: series,
        isStacked: stacked,
        bar: { 
          groupWidth: '100%'
        }
      };

      if(chart_kind == 'line') {
        var chart = new google.visualization.LineChart(document.getElementById('chart-data-holder-over-time'));
      } else if(chart_kind == 'column') {
        var chart = new google.visualization.ColumnChart(document.getElementById('chart-data-holder-over-time'));
      }

      google.visualization.events.addListener(chart, 'select', disableClick);
      google.visualization.events.addListener(chart, 'onmouseover', disableClick);

      function disableClick(item) {
        var disabled_element = 0

        if(item === null) {
          if(chart.getSelection().length > 0) {
            disabled_element = chart.getSelection()[0]['column'] 
          } else {
            disabled_element = 0
          }
        } else {
          disabled_element = item['column']
        }

        if(disabled_element != enabed_column){
          chart.setSelection([{}])
        }
      }

      // Need to hide the loader and show the chart before trying to draw it.
      super.hideLoader('wj_')


      chart.draw(data, options);

      $("#image_data").val(chart.getImageURI());

      // Update chart table data
      var wj_table = JSON.parse(JSON.stringify(wj)) // This is duplicating the object (wj)
      document.wjControllerRef.updateOverTimeTable(wj_table, chart_data)
    }
  }

  /* **************************************************************************
  function: updateOverTimeTable
  * System triggered
  * Reloads the table data
  ************************************************************************** */
  updateOverTimeTable(data, chart_type) {
    console.log("WJ: updateOverTimeTable")

    // let time_period = document.getElementById('wj_time_period_list').value.split(',')
    var time_period = undefined
    let wj_time_period = document.querySelector('input[name="wj_time_period"]:checked').value
    if(wj_time_period == 'campaigns' || wj_time_period == 'interval') {
      document.getElementById('wj-time-period-select').classList.remove('hidden')
      if(wj_time_period == 'campaigns'){
        time_period = JSON.parse(document.getElementById('wj_time_period_campaign').value)
      }
      if(wj_time_period == 'interval'){
        var checked_interval = document.querySelector('input[name="wj_time_interval"]:checked').value
        if(checked_interval == 'annually') {
          time_period = JSON.parse(document.getElementById('wj_time_period_annually').value)
        } else if(checked_interval == 'bi-annually') {
          time_period = JSON.parse(document.getElementById('wj_time_period_biannually').value)
        } else if(checked_interval == 'quarterly') {
          time_period = JSON.parse(document.getElementById('wj_time_period_quarterly').value)
        } else if(checked_interval == 'monthly') {
          time_period = JSON.parse(document.getElementById('wj_time_period_monthly').value)
        }
      }
      
    } else {
      document.getElementById('wj-time-period-select').classList.add('hidden')
      if(wj_time_period == 'date-range') {
        var time_period = []
        data['rows'].forEach((item) => {
          time_period.push([item[0]['campaign_name'], true])
        })
      }
    }

    var general_data = document.over_time_chart_data.general
    var content = '<p><strong>' + general_data['wbi_version_plural'] + ' ' + I18n.wellness_journey.over_time  + '</strong>'
    
    if(general_data['limited_data']) {
      content += general_data['limited_data']
    }

    content += '</p>'

    content += '<div id="table-data"><table class="table text-small table-striped">'
    if(chart_type == 'mean_distress') {

      if(document.getElementById('wj_mean_national_average').value == 'show') { 
        content += '<thead><th>' + I18n.wellness_snapshot.campaign + '</th><th>' + I18n.wellness_journey.mean_distress_score + ' <label class="text-info" data-bs-toggle="tooltip" data-bs-placement="right" title="' + I18n.wellness_journey.sd_description + '">(' + I18n.wellness_journey.sd_title + ')</label></th><th>' + I18n.wellness_journey.national_average + '<label class="text-info" data-bs-toggle="tooltip" data-bs-placement="right" title="' + I18n.wellness_journey.sd_description + '">(' + I18n.wellness_journey.sd_title + ')</label></th><th>' + I18n.wellness_journey.total + '</th></thead><tbody>'

        data['rows'].forEach((item) => {
          time_period.forEach((time) => {
            if(time[0] == item[0].campaign_name && time[1] == true){
              let general = item.shift()
              if(general['total'] > 0) {
                content += '<tr><td>' + general['campaign_name'] + '<br /><small>' + general['start_date'] + ' ' + I18n.wellness_journey.to + ' ' + general['end_date'] + '</small></td><td>' + general['value'] + '</td><td>' + general['national_average'] + '</td><td>' + general['total'] + '</td></tr>'
              }
            }
          })
        })
      } else {
        content += '<thead><th>' + I18n.wellness_snapshot.campaign + '</th><th>' + I18n.wellness_journey.mean_distress_score + ' <label class="text-info" data-bs-toggle="tooltip" data-bs-placement="right" title="' + I18n.wellness_journey.sd_description + '">(' + I18n.wellness_journey.sd_title + ')</label></th><th>' + I18n.wellness_journey.total + '</th></thead><tbody>'

        data['rows'].forEach((item) => {
          time_period.forEach((time) => {
            if(time[0] == item[0].campaign_name && time[1] == true){
              let general = item.shift()
              if(general['total'] > 0) {
                content += '<tr><td>' + general['campaign_name'] + '<br /><small>' + general['start_date'] + ' ' + I18n.wellness_journey.to + ' ' + general['end_date'] + '</small></td><td>' + general['value'] + '</td><td>' + general['total'] + '</td></tr>'
              }
            }
          })
        })
      }

    } else if(chart_type == 'snapshot') {
      content += '<thead><th>' + I18n.wellness_snapshot.campaign + '</th><th>' + I18n.wellness_journey.distressed + '</th><th>' + I18n.wellness_journey.struggling + '</th><th>' + I18n.wellness_snapshot.okay + '</th><th>' + I18n.wellness_journey.thriving + '</th><th>' + I18n.wellness_journey.total + '</th></thead><tbody>'
      data['rows'].forEach((item) => {
        
        if(time_period !== undefined){
          time_period.forEach((time) => {
            if(time[0] == item[0].campaign_name && time[1] == true){
              let general = item.shift()
              if(general['total'] > 0) {
                content += '<tr><td>' + general['campaign_name'] + '<br /><small>' + general['start_date'] + ' ' + I18n.wellness_journey.to + ' ' + general['end_date'] + '</small></td><td>' + (general['distressed'] == 'NaN%' ? '0' : general['distressed']) + '</td><td>' + general['struggling'] + '</td><td>' + general['neutral'] + '</td><td>' + general['thriving'] + '</td><td>' + general['total'] + '</td></tr>'
              }
            }
          })
        }
      })
    } else if(chart_type == 'question') {
      content += '<thead><th>' + I18n.wellness_snapshot.campaign + '</th><th>' + I18n.wellness_journey.distressed + '</th><th>' + I18n.wellness_journey.struggling + '</th><th>' + I18n.wellness_snapshot.okay + '</th><th>' + I18n.wellness_journey.thriving + '</th><th>' + I18n.wellness_journey.total + '</th></thead><tbody>'
      data['rows'].forEach((item) => {
        let general = item.shift()
        if(general['total'] > 0){
          content += '<tr><td>' + general['campaign_name'] + '<br /><small>' + general['start_date'] + ' ' + I18n.wellness_journey.to + ' ' + general['end_date'] + '</small></td><td>' + general['distressed'] + '</td><td>' + general['struggling'] + '</td><td>' + general['neutral'] + '</td><td>' + general['thriving'] + '</td><td>' + general['total'] + '</td></tr>'
        }
      })
    }

    content += '</tbody></table></div>'

    $('#export_chart_total_text').val(content);
    document.getElementById('wj_table-data-holder').innerHTML = content
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })

  }

}
