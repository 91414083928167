import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { Offcanvas } from "bootstrap"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
  }

  clearTooltips() {
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })
  }

  loadModal(event) {
    this.modal = new Modal(document.getElementById('system_modal_popup'), {
      keyboard: false
    });

    if (event.currentTarget.dataset && event.currentTarget.dataset.modalSize) {
      let modal_container = document.querySelector('#system_modal_popup');
      if (modal_container) {
        let dialog_container = modal_container.querySelector('.modal-dialog');
        if (dialog_container) {
          dialog_container.className = "modal-dialog " + event.currentTarget.dataset.modalSize;
        }
      }
    }

    this.modal.show();
    this.clearTooltips();
  }

  loadInnerModal(event) {
    this.modal = new Modal(document.getElementById('inner_modal_popup'), {
      keyboard: false
    });

    if (event.currentTarget.dataset && event.currentTarget.dataset.modalSize) {
      let modal_container = document.querySelector('#inner_modal_popup');
      if (modal_container) {
        let dialog_container = modal_container.querySelector('.modal-dialog');
        if (dialog_container) {
          dialog_container.className = "modal-dialog " + event.currentTarget.dataset.modalSize;
        }
      }
    }

    this.modal.show();
    this.clearTooltips();
  }

  loadLoginAsModal(event) {
    $("#recent-customers-holder").hide();
    $("#organization-search-holder").hide();
    this.modal = new Modal(document.getElementById('login_as_modal_popup'), {
      keyboard: false
    });

    if (event.currentTarget.dataset && event.currentTarget.dataset.modalSize) {
      let modal_container = document.querySelector('#login_as_modal_popup');
      if (modal_container) {
        let dialog_container = modal_container.querySelector('.modal-dialog');
        if (dialog_container) {
          dialog_container.className = "modal-dialog " + event.currentTarget.dataset.modalSize;
        }
      }
    }

    this.modal.show();
    this.clearTooltips();
  }
  
  loadSupportPanel(event) {

    this.panel = new Offcanvas(document.getElementById('admin_support'), {
      keyboard: false
    });

   this.panel.show()
   this.clearTooltips();
  }


  verify_2fa_enable(event) {
    event.preventDefault();
    $('#error-message-2fa').remove();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: '/sessions/verify_mfa_account',
      type: 'post',
      data: { code: $('#verify_code').val() },
      success: function(e) {
        if(e.status == 200) {
          $('#enable-2fa-modal').modal('hide');
          $('#button-2fa-holder').html('<p class="alert alert-success">2-Factor Authentication is enabled.</p><p><button class="btn btn-danger" id="disable_2fa_button" data-action="click->account-setup-verification#disable2fa">Disable 2-Factor Authentication</button></p>');
          $('#sms_phone_label').removeClass('hidden');
          $('#user_sms_phone').attr("disabled", "disabled");
          $('#enable-2fa-modal').modal('hide');
        }
        if(e.status == 500) {
          $('#enable-2fa-modal .modal-body').prepend("<p class='text-danger' id='error-message-2fa'>" + e.message + "</p>")
        }
      }
    });
  }
}
