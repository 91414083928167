import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="participants"
export default class extends Controller {
  connect() {
  }

  cancelParticipantsUpdate(event) {
    event.preventDefault()
    $("#number_participants_display_div").show()
    $("#number_participants_change_div").hide()
  }

  changeParticipants(event) {
    event.preventDefault()
    $("#number_participants_display_div").hide()
    $("#number_participants_change_div").show()
  }

  clipboardHTML(event) {
    var item = event.currentTarget
    var html_content = $("#invite_email_content_input").val();

    var copy_success = copy_to_clipboard(html_content);
    $(item).blur();
    if(copy_success) {
      $("#copy_msg_example_email_body_all").show().delay(5000).fadeOut('fast');
    } else {
      $('#copy_error_msg_example_email_body_all').show().delay(5000).fadeOut('fast');
    }
  }

  copySignupUrl(event) {
    var item = event.currentTarget
    var html_content = $("#signup_url").html();
    if ($(item).data("linktarget")) {
      html_content = $("#"+$(item).data("linktarget")).html();
    }

    var copy_success = copy_to_clipboard(html_content);
    $(item).blur();
    if(copy_success) {
      $("#signup_url_success").show().delay(5000).fadeOut('fast');
    }
  }

  saveParticipants(event) {
    $("#number_participants_display_div").show()
    $("#number_participants_change_div").hide()
    $("#participation_out_of_display").html( $("#customer_sub_account_participation_outof").val())
  }

  toggleMultiSmsInvite(event) {
    if(event.target.checked == true) {
      $("#send_sms_invite_btn_multi").prop("disabled", false);
    } else {
      $("#send_sms_invite_btn_multi").prop("disabled", true);
    }
  }

  toggleSmsCountry(event) {
    if(event.target.checked == true) {
      $("#sms_upload_set_country_div").show();
    } else {
      $("#sms_upload_set_country_div").hide();
    }
  }

  toggleSmsInvite(event) {
    if(event.target.checked == true) {
      $("#send_sms_invite_btn").prop("disabled", false);
    } else {
      $("#send_sms_invite_btn").prop("disabled", true);
    }
  }

  validateRemoval(event) {
    var ans = confirm(i18n_string("participants.are_you_sure_you_want_to_remove_this_test_account","Are you sure you want to remove this test account?"));
    if(ans) {}
    else {
      event.preventDefault()
    }
  }
  
}
